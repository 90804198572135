import https from 'https';
import axios from 'axios';

const BASE_URL = 'https://klantcloud.nl';

const agent = new https.Agent({
    rejectUnauthorized: false,
});

const config = {
    baseURL: BASE_URL,
    httpsAgent: agent,
    headers: {
        'API-KEY': window.adpiapitoken,
        'VESTIGING-KEY': window.adpiapiid,
    },
};

export const getInterestRatesBasic = async function(comparemortgagedetails, headers) {
    try {
        config.params = comparemortgagedetails;
        config.headers = headers;
        
        return await axios
        .get(`/api/rentevergelijker/basic`, config)
        .then((response) => response.data);
    } catch (error) {
        console.log(error);
    }
};

export const getInterestRatesBasicApi = async function(comparemortgagedetails, headers) {
    try {
        config.baseURL = window.location.origin;
        config.params = comparemortgagedetails;
        config.headers = headers;
        
        return await axios
        .get(`/api/v1/rentevergelijker/basic`, config)
        .then((response) => response.data);
    } catch (error) {
        console.log(error);
    }
};
