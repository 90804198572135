<template>
    <div class="compareMortgageProviderForm">
        <b-container fluid>
            <b-form-row>
                <b-col
                    md="4"
                    sm="12"
                >
                    <b-form-group
                        id="fieldset-1"
                        :label="
              langcode === 'nl'
                ? 'Wil je de hypotheek afsluiten onder NHG?'
                : 'Do you want to close the mortgage with NHG?'
            "
                        label-for="input-1"
                    >
                        <b-input-group class="mt-3">
                            <b-form-select
                                v-model="comparemortgagedetails.nhg"
                                :options="nhgOptions"
                            ></b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col
                    v-if="!isAdvisor"
                    md="4"
                    sm="12"
                >
                    <b-form-group
                        id="fieldset-1"
                        :invalid-feedback="invalidFeedbackRequiredMortgage"
                        :label="
              langcode === 'nl'
                ? 'Wat wordt de hoogte van de hypotheek?'
                : 'What will be the mortgage amount?'
            "
                        :state="stateRequiredMortgage"
                        label-for="input-1"
                    >
                        <b-input-group
                            class="mt-3"
                            prepend="€"
                        >
                            <b-form-input
                                v-model="comparemortgagedetails.requiredMortgage"
                                type="number"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col
                    v-if="!isAdvisor"
                    md="4"
                    sm="12"
                >
                    <b-form-group
                        id="fieldset-1"
                        :invalid-feedback="invalidFeedbackPropertyValue"
                        :label="
              langcode === 'nl'
                ? 'Wat is de waarde van de woning?'
                : 'What is the property value?'
            "
                        :state="statePropertyValue"
                        label-for="input-1"
                    >
                        <b-input-group
                            class="mt-3"
                            prepend="€"
                        >
                            <b-form-input
                                v-model="comparemortgagedetails.propertyValue"
                                type="number"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                
                <b-col
                    v-if="isAdvisor"
                    md="4"
                    sm="12" 
                >
                    <b-form-group
                        id="fieldset-1"
                        label="Loan to value"
                        label-for="ltv"
                    >
                        <b-input-group
                            class="mt-3"
                            append="%"
                        >
                            <b-form-input
                                v-model="loanToValue"
                                type="number"
                                placeholder="50"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col
                    md="4"
                    sm="12"
                >
                    <b-form-group
                        id="fieldset-1"
                        :label="
              langcode === 'nl'
                ? 'Hoe lang wil je de rente vast zetten?'
                : 'Which fixed term do you want to compare?'
            "
                        label-for="input-1"
                    >
                        <b-input-group>
                            <b-form-select
                                v-model="comparemortgagedetails.fixedTerm"
                                :options="fixedTermOptions"
                            ></b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col
                    md="4"
                    sm="12"
                >
                    <b-form-group
                        id="fieldset-1"
                        :label="
              langcode === 'nl'
                ? 'Hoe wil je de hypotheek aflossen?'
                : 'How do you want to repay the mortgage?'
            "
                        label-for="input-1"
                    >
                        <b-input-group>
                            <b-form-select
                                v-model="comparemortgagedetails.paymentType"
                                :options="paymentTypeOptions"
                            ></b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col
                    md="4"
                    sm="12"
                >
                    <b-form-group
                        id="fieldset-1"
                        :label="
              langcode === 'nl'
                ? 'Wat is het energielabel van de woning?'
                : 'What is the energy-label of the property?'
            "
                        label-for="input-1"
                    >
                        <b-input-group>
                            <b-form-select
                                v-model="comparemortgagedetails.energyCertificate"
                                :options="energyCertificateOptions"
                            ></b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <b-form-row>
                <b-col>
                    <b-button
                        style="width: 100%"
                        variant="primary"
                        @click="submitVergelijk()"
                    >{{ langcode === 'nl' ? 'Vergelijk' : 'Compare' }}
                    </b-button
                    >
                </b-col>
            </b-form-row>
        </b-container>
    </div>
</template>

<script>
    import eventBus from '@/helpers/eventBus.js';
    import {
        BButton,
        BCol,
        BContainer,
        BFormGroup,
        BFormInput,
        BFormRow,
        BFormSelect,
        BInputGroup
    } from 'bootstrap-vue';

    export default {
        components: {
            BCol,
            BFormRow,
            BFormGroup,
            BFormSelect,
            BContainer,
            BInputGroup,
            BFormInput,
            BButton
        },

        props: {
            langcode: {
                type: String,
                default: 'nl'
            },
            isAdvisor: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        data() {
            return {
                loanToValue: 100,
                comparemortgagedetails: {
                    paymentType: 'annuitair',
                    fixedTerm: 120,
                    nhg: 1,
                    requiredMortgage: 280000,
                    propertyValue: 280000,
                    energyCertificate: '02 A+',
                },

                invalidFeedbackRequiredMortgage: null,
                stateRequiredMortgage: true,
                invalidFeedbackPropertyValue: null,
                statePropertyValue: true,

                energyCertificateOptions: [
                    { text: 'Nul-op-de-meter', value: '13 Nul-op-de-Meter' },
                    { text: 'Energie Neutraal', value: '12 Energie Neutraal' },
                    { text: 'A++++', value: '11 A++++' },
                    { text: 'A+++', value: '10 A+++' },
                    { text: 'A++', value: '01 A++' },
                    { text: 'A+', value: '02 A+' },
                    { text: 'A', value: '03 A' },
                    { text: 'B', value: '04 B' },
                    { text: 'C', value: '05 C' },
                    { text: 'D', value: '06 D' },
                    { text: 'E', value: '07 E' },
                    { text: 'F', value: '08 F' },
                    { text: 'G', value: '09 G' },
                ],
            };
        },

        mounted: function () {
            this.$nextTick(function () {
                eventBus.$emit(
                    'comparemortgagedetails:compare',
                    this.comparemortgagedetails
                );
            });
        },
        
        watch: {
          loanToValue: {
              handler(value) {
                  if(!this.isAdvisor) {
                      return
                  }
                  
                  // calculate debt based on ltv
                  this.comparemortgagedetails.propertyValue = 100000;
                  this.comparemortgagedetails.requiredMortgage = this.comparemortgagedetails.propertyValue * (value/100);
              },
              immediate: true
          }
        },

        computed: {
            paymentTypeOptions: function () {
                if (this.langcode === 'nl') {
                    return [
                        { text: 'annuïtair', value: 'annuitair' },
                        { text: 'lineair', value: 'linear' },
                        { text: 'aflosvrij', value: 'aflosvrij' },
                    ];
                }

                return [
                    { text: 'annuity', value: 'annuitair' },
                    { text: 'linear', value: 'linear' },
                    { text: 'interest only', value: 'aflosvrij' },
                ];
            },

            nhgOptions: function () {
                if (this.langcode === 'nl') {
                    return [
                        { text: 'Ja', value: 1 },
                        { text: 'Nee', value: 0 },
                    ];
                }

                return [
                    { text: 'Yes', value: 1 },
                    { text: 'No', value: 0 },
                ];
            },

            fixedTermOptions: function () {
                if (this.langcode === 'nl') {
                    return [
                        { value: 120, text: '10 jaar' },
                        { value: 180, text: '15 jaar' },
                        { value: 240, text: '20 jaar' },
                        { value: 360, text: '30 jaar' },
                        { value: 1, text: '1 maand' },
                        { value: 3, text: '3 maand' },
                        { value: 6, text: '6 maand' },
                        { value: 12, text: '1 jaar' },
                        { value: 24, text: '2 jaar' },
                        { value: 36, text: '3 jaar' },
                        { value: 48, text: '4 jaar' },
                        { value: 60, text: '5 jaar' },
                        { value: 72, text: '6 jaar' },
                        { value: 84, text: '7 jaar' },
                        { value: 96, text: '8 jaar' },
                        { value: 108, text: '9 jaar' },
                        { value: 132, text: '11 jaar' },
                        { value: 144, text: '12 jaar' },
                        { value: 156, text: '13 jaar' },
                        { value: 168, text: '14 jaar' },
                        { value: 192, text: '16 jaar' },
                        { value: 204, text: '17 jaar' },
                        { value: 216, text: '18 jaar' },
                        { value: 228, text: '19 jaar' },
                        { value: 252, text: '21 jaar' },
                        { value: 264, text: '22 jaar' },
                        { value: 276, text: '23 jaar' },
                        { value: 288, text: '24 jaar' },
                        { value: 300, text: '25 jaar' },
                        { value: 312, text: '26 jaar' },
                        { value: 324, text: '27 jaar' },
                        { value: 336, text: '28 jaar' },
                        { value: 348, text: '29 jaar' },
                    ];
                }

                return [
                    { value: 120, text: '10 year' },
                    { value: 180, text: '15 year' },
                    { value: 240, text: '20 year' },
                    { value: 360, text: '30 year' },
                    { value: 1, text: '1 month' },
                    { value: 3, text: '3 months' },
                    { value: 6, text: '6 months' },
                    { value: 12, text: '1 year' },
                    { value: 24, text: '2 year' },
                    { value: 36, text: '3 year' },
                    { value: 48, text: '4 year' },
                    { value: 60, text: '5 year' },
                    { value: 72, text: '6 year' },
                    { value: 84, text: '7 year' },
                    { value: 96, text: '8 year' },
                    { value: 108, text: '9 year' },
                    { value: 132, text: '11 year' },
                    { value: 144, text: '12 year' },
                    { value: 156, text: '13 year' },
                    { value: 168, text: '14 year' },
                    { value: 192, text: '16 year' },
                    { value: 204, text: '17 year' },
                    { value: 216, text: '18 year' },
                    { value: 228, text: '19 year' },
                    { value: 252, text: '21 year' },
                    { value: 264, text: '22 year' },
                    { value: 276, text: '23 year' },
                    { value: 288, text: '24 year' },
                    { value: 300, text: '25 year' },
                    { value: 312, text: '26 year' },
                    { value: 324, text: '27 year' },
                    { value: 336, text: '28 year' },
                    { value: 348, text: '29 year' },
                ];
            },
        },

        methods: {
            submitVergelijk() {
                if (!this.validateComparemortgagedetails()) {
                    return;
                }

                eventBus.$emit(
                    'comparemortgagedetails:compare',
                    this.comparemortgagedetails
                );
            },

            validateComparemortgagedetails() {
                this.stateRequiredMortgage = true;
                this.invalidFeedbackPropertyValue = null;
                this.statePropertyValue = true;
                this.invalidFeedbackPropertyValue = null;

                if (this.comparemortgagedetails.nhg) {
                    return true;
                }

                if (this.comparemortgagedetails.requiredMortgage <= 0) {
                    this.stateRequiredMortgage = false;
                    this.invalidFeedbackRequiredMortgage =
                        'Wanneer je niet onder NHG leent hebben we de benodigde hypotheek nodig om de rente te kunnen berekenen. Dit omdat de geldverstrekker de rente bepaald op basis van schuld-marktwaarde verhouding';
                }

                if (this.comparemortgagedetails.propertyValue <= 0) {
                    this.statePropertyValue = false;
                    this.invalidFeedbackPropertyValue =
                        'Wanneer je niet onder NHG leent hebben we de marktwaarde nodig om de rente te berekenen. Dit omdat de geldverstrekker de rente bepaald op basis van schuld-marktwaarde verhouding';
                }

                if (!this.stateRequiredMortgage || !this.statePropertyValue) {
                    return false;
                }

                return true;
            },
        },
    };
</script>

<style lang="scss">
    .compareMortgageProviderForm {
        border: 1px solid #e1e1e1;
        border-bottom: none;
        padding-bottom: 32px;
        padding-top: 32px;
    }
</style>
