var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"compareMortgageProviderForm"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-form-row',[_c('b-col',{attrs:{"md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"id":"fieldset-1","label":_vm.langcode === 'nl'
            ? 'Wil je de hypotheek afsluiten onder NHG?'
            : 'Do you want to close the mortgage with NHG?',"label-for":"input-1"}},[_c('b-input-group',{staticClass:"mt-3"},[_c('b-form-select',{attrs:{"options":_vm.nhgOptions},model:{value:(_vm.comparemortgagedetails.nhg),callback:function ($$v) {_vm.$set(_vm.comparemortgagedetails, "nhg", $$v)},expression:"comparemortgagedetails.nhg"}})],1)],1)],1),(!_vm.isAdvisor)?_c('b-col',{attrs:{"md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"id":"fieldset-1","invalid-feedback":_vm.invalidFeedbackRequiredMortgage,"label":_vm.langcode === 'nl'
            ? 'Wat wordt de hoogte van de hypotheek?'
            : 'What will be the mortgage amount?',"state":_vm.stateRequiredMortgage,"label-for":"input-1"}},[_c('b-input-group',{staticClass:"mt-3",attrs:{"prepend":"€"}},[_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.comparemortgagedetails.requiredMortgage),callback:function ($$v) {_vm.$set(_vm.comparemortgagedetails, "requiredMortgage", $$v)},expression:"comparemortgagedetails.requiredMortgage"}})],1)],1)],1):_vm._e(),(!_vm.isAdvisor)?_c('b-col',{attrs:{"md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"id":"fieldset-1","invalid-feedback":_vm.invalidFeedbackPropertyValue,"label":_vm.langcode === 'nl'
            ? 'Wat is de waarde van de woning?'
            : 'What is the property value?',"state":_vm.statePropertyValue,"label-for":"input-1"}},[_c('b-input-group',{staticClass:"mt-3",attrs:{"prepend":"€"}},[_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.comparemortgagedetails.propertyValue),callback:function ($$v) {_vm.$set(_vm.comparemortgagedetails, "propertyValue", $$v)},expression:"comparemortgagedetails.propertyValue"}})],1)],1)],1):_vm._e(),(_vm.isAdvisor)?_c('b-col',{attrs:{"md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Loan to value","label-for":"ltv"}},[_c('b-input-group',{staticClass:"mt-3",attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"50"},model:{value:(_vm.loanToValue),callback:function ($$v) {_vm.loanToValue=$$v},expression:"loanToValue"}})],1)],1)],1):_vm._e()],1),_c('b-form-row',[_c('b-col',{attrs:{"md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"id":"fieldset-1","label":_vm.langcode === 'nl'
            ? 'Hoe lang wil je de rente vast zetten?'
            : 'Which fixed term do you want to compare?',"label-for":"input-1"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"options":_vm.fixedTermOptions},model:{value:(_vm.comparemortgagedetails.fixedTerm),callback:function ($$v) {_vm.$set(_vm.comparemortgagedetails, "fixedTerm", $$v)},expression:"comparemortgagedetails.fixedTerm"}})],1)],1)],1),_c('b-col',{attrs:{"md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"id":"fieldset-1","label":_vm.langcode === 'nl'
            ? 'Hoe wil je de hypotheek aflossen?'
            : 'How do you want to repay the mortgage?',"label-for":"input-1"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"options":_vm.paymentTypeOptions},model:{value:(_vm.comparemortgagedetails.paymentType),callback:function ($$v) {_vm.$set(_vm.comparemortgagedetails, "paymentType", $$v)},expression:"comparemortgagedetails.paymentType"}})],1)],1)],1),_c('b-col',{attrs:{"md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"id":"fieldset-1","label":_vm.langcode === 'nl'
            ? 'Wat is het energielabel van de woning?'
            : 'What is the energy-label of the property?',"label-for":"input-1"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"options":_vm.energyCertificateOptions},model:{value:(_vm.comparemortgagedetails.energyCertificate),callback:function ($$v) {_vm.$set(_vm.comparemortgagedetails, "energyCertificate", $$v)},expression:"comparemortgagedetails.energyCertificate"}})],1)],1)],1)],1),_c('b-form-row',[_c('b-col',[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submitVergelijk()}}},[_vm._v(_vm._s(_vm.langcode === 'nl' ? 'Vergelijk' : 'Compare')+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }