<template>
    <div class="compareMortgageProviderDisplay">
        <div
            v-if="loading"
            class="loadingBox"
        >
            <h5>
                {{
                    langcode === 'nl'
                        ? 'Een moment geduld, we halen de rente op.'
                        : 'One moment please. We are collecting the interest rates.'
                }}
            </h5>
        </div>
        <div
            v-if="!loading"
            class="interestRateBoxWrapper"
        >
            <div
                v-for="(sortedInterestRate, key) in sortedInterestRates"
                :key="key"
                class="interestRateBox"
            >
                <div class="alignLeft">
                    <div class="logo">
                        <b-img
                            :src="sortedInterestRate.image"
                            fluid
                        ></b-img>
                    </div>
                    <div class="providerDetails">
                        <div class="provider">
                            <h5>{{ sortedInterestRate.maatschappij }}</h5>
                        </div>
                        <div class="label">
                            <h6>{{ sortedInterestRate.label }}</h6>
                        </div>
                    </div>
                </div>
                <div class="alignRight">
                    <div class="interestRate">
                        <h5>{{ formatPercentage(sortedInterestRate.rente) }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import eventBus from '@/helpers/eventBus.js';
    import { getInterestRatesBasic, getInterestRatesBasicApi } from '@/helpers/adpi';
    import { BImg } from 'bootstrap-vue';

    export default {
        components: {
            BImg
        },

        props: {
            langcode: {
                type: String,
                default: 'nl'
            },
            apiKey: {
                type: String,
            },
            officeId: {
                type: String,
            },
            isAdvisor: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        data() {
            return {
                interestRates: [],
                loading: true,
            };
        },

        computed: {
            filteredInterestRates: function () {
                return _.filter(this.interestRates, function ( o ) {
                    return o.rente > 0;
                });
            },

            sortedInterestRates: function () {
                return _.orderBy(this.filteredInterestRates, 'rente');
            },
        },

        mounted: function () {
            eventBus.$on('comparemortgagedetails:compare', ( comparemortgagedetails ) => {
                this.fetch(comparemortgagedetails)
                    .then(() => {
                        this.sendPostMessage();
                        setTimeout(() => {
                            this.sendPostMessage();
                        }, 1000);
                        setTimeout(() => {
                            this.sendPostMessage();
                        }, 2000);
                        setTimeout(() => {
                            this.sendPostMessage();
                        }, 3000);
                    });
            });

            //this.langcode = window.adpilangcode;
        },

        beforeDestroy() {
            eventBus.$off('comparemortgagedetails:compare', this.fetch);
        },

        methods: {
            async fetch( comparemortgagedetails ) {
                const headers = {
                    "API-KEY": this.apiKey,
                    "VESTIGING-KEY": this.officeId,
                    "IS-ADVISOR": this.isAdvisor
                }

                if(this.isAdvisor) {
                    this.interestRates = await getInterestRatesBasicApi(comparemortgagedetails, headers)
                    .then(( data ) => {
                        this.loading = false;
                        return data ? data.interestRates : [];
                    })
                    .catch(( error ) => console.error(error));
                    
                    return;
                }
                
                this.interestRates = await getInterestRatesBasic(comparemortgagedetails, headers)
                    .then(( data ) => {
                        this.loading = false;
                        return data ? data.interestRates : [];
                    })
                    .catch(( error ) => console.error(error));
            },

            formatPercentage( value ) {
                if (value || value === 0) {
                    return value.toString().replace('.', ',') + '%';
                }

                return value;
            },

            sendPostMessage() {
                let height;
                if (!document.getElementById('adpirentevergelijker')) {
                    return;
                }
                if (height !== document.getElementById('adpirentevergelijker').offsetHeight) {
                    height = document.getElementById('adpirentevergelijker').offsetHeight;
                    window.parent.postMessage({
                        frameHeight: height
                    }, '*');
                    console.log(height); // check the message is being sent correctly
                }
            }
        },
    };
</script>

<style lang="scss">
    .compareMortgageProviderDisplay {
        .loadingBox {
            align-items: center;
            border: 1px solid #e1e1e1;
            display: flex;
            flex-direction: column;
            min-height: 900px;
            padding: 32px 16px 24px;
        }

        .interestRateBoxWrapper {
            border: 1px solid #e1e1e1;
            min-height: 900px;
            padding: 32px 16px 24px;
        }

        .interestRateBox {
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 0 25px 0 rgba(113, 150, 193, 0.2);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 15px;
            padding: 32px 16px 24px;

            @media (max-width: 765px) {
                flex-direction: column;
                text-align: center;
            }

            .alignLeft {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @media (max-width: 765px) {
                    align-items: center;
                    border-bottom: 1px solid #001240;
                    flex-direction: column;
                    justify-content: center;
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                }
            }

            .logo {
                min-width: 100px;

                img {
                    max-width: 100px;
                }
            }

            .providerDetails {
                margin-left: 25px;

                @media (max-width: 765px) {
                    margin-left: 0px;

                    margin-top: 15px;
                    .provider {
                        display: none;
                    }
                }
            }

            .interestRate {
                min-width: 55px;
            }
        }
    }
</style>
