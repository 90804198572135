<template>
    <div>
        <CompareMortgageProviderForm
            :langcode="langcode"
            :is-advisor="isAdvisor"
        />

        <CompareMortgageProviderDisplay
            :langcode="langcode"
            :api-key="apiKey"
            :office-id="officeId"
            :is-advisor="isAdvisor"
        />
    </div>
</template>

<script>
    import { defineComponent } from 'vue';

    import CompareMortgageProviderForm from '@/components/CompareMortgageProvider/CompareMortgageProviderForm.vue';
    import CompareMortgageProviderDisplay
        from '@/components/CompareMortgageProvider/CompareMortgageProviderDisplay.vue';

    export default defineComponent({
        components: {
            CompareMortgageProviderForm,
            CompareMortgageProviderDisplay
        },

        props: {
            langcode: {
                type: String,
                default: 'nl'
            },
            apiKey: {
                type: String,
            },
            officeId: {
                type: String,
            },
            isAdvisor: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    });
</script>

<style lang="scss">
    @import '~bootstrap/scss/bootstrap.scss';
    @import '~bootstrap-vue/src/index.scss';

    * {
        font-family: 'Ubuntu', sans-serif;
    }
</style>
